/* QUESTIONS -------------------------------------------------------------*/

.question-container {
  width: 90%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
}

.question-card {
  padding: 0 10px 2px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #cbcbcb;

  &.invisible {
    background: none;
    border: none;
    box-shadow: none;
  }
}

.card,
.flippableCard {
  height: 50%;

  .ant-btn-primary .anticon {
    color: inherit;
  }

  &.card-h1 {
    height: 100%;
  }

  &.card-w2 {
    width: 50%;
  }

  &.card-w3 {
    width: calc(100% / 3);
  }

  &__face {
    height: 100%;
    width: 100%;
  }
}

.standaloneCard {
  &,
  & .flippableCard {
    height: 100%;
  }
}

.flippableCard {
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;

  &__face {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;

    &--back {
      transform: rotateY(180deg);
    }
  }

  &.flipped {
    transform: rotateY(180deg);
  }
}

.plenary-feedback-card {
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  max-height: 50vh;

  h1 {
    font-size: 1.9rem;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1;
  }

  .ant-form {
    .feedback-input,
    .feedback-select .ant-select-selector {
      border-radius: 0.5rem;
    }

    .anticon {
      color: $primary;
    }
  }

  .add-new-btn {
    border-radius: 0.5rem;
    font-size: 1.3rem;
    margin-right: 0.5rem;
  }

  .edit-btn {
    color: $gray-7;
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    color: $gray-7;
  }

  &-title {
    text-align: start;
    flex-grow: 1;

    button {
      font-size: 1.4rem;
      height: 21px !important;
      margin-left: 0;
    }
  }

  .card__face {
    justify-content: flex-start;

    &.red {
      background: #ffe5e8;
      &,
      .feedback-input,
      .feedback-select .ant-select-selector {
        border: 1px solid #ff295d;
      }
    }

    &.blue {
      background: #e5f7ff;
      &,
      .feedback-input,
      .feedback-select .ant-select-selector {
        border: 1px solid #1660e7;
      }
    }

    &.green {
      background: #e4feef;
      &,
      .feedback-input,
      .feedback-select .ant-select-selector {
        border: 1px solid #2ecd46;
      }
    }

    &.teal {
      background: #dcf7ef;
      &,
      .feedback-input,
      .feedback-select .ant-select-selector {
        border: 1px solid #55b48c;
      }
    }

    &.gold {
      background: #fbf3d2;
      &,
      .feedback-input,
      .feedback-select .ant-select-selector {
        border: 1px solid #d3ad39;
      }
    }
  }

  .listFormItem {
    margin-bottom: 1.3rem;

    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: row;
    }

    .ant-select {
      max-width: 9rem;
      margin: 0 3px;
    }

    .buttonPlaceholder {
      width: 32px;
    }
  }

  .bottomText {
    margin: auto 0 0;
    font-size: 1.4rem;
    color: #272727;
    text-align: center;
  }
}

.editTitleForm {
  .ant-form-item {
    margin-bottom: 0.5rem;
  }
}

.plenary-feedback-explanation {
  max-height: 100%;
  padding: 0.25rem 1rem 0.5rem;

  .ant-btn-text {
    padding: 0;
    color: $primary;
    font-weight: 600;
    margin-bottom: 0;
  }

  .ant-list-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .ant-list-item {
    padding: 0;
    border-bottom: none !important;

    &,
    .anticon,
    .ant-list-item-meta-title > a:not(.ant-btn-disabled) {
      color: $primary;
    }

    .ant-list-item-meta-title > a.ant-btn-disabled {
      color: rgba(0, 0, 0, 0.25);
    }

    .ant-list-item-meta-avatar {
      margin-right: 0.5rem;
    }

    .ant-btn-link {
      padding: 0;
      white-space: normal;
      text-align: left;
      height: fit-content;
    }
  }

  .ant-list-footer {
    padding: 0;
    width: fit-content;
    max-width: 100%;

    .ant-list-item-meta-content {
      width: 100%;
    }
  }

  .ant-btn-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    font-size: 0.9em;
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;

    span {
      white-space: normal;
      text-align: left;
    }
  }

  p,
  .ant-btn-text,
  .ant-btn-link {
    font-size: 1.5rem; // Fallback
    font-size: clamp(1rem, 1.6rem, 1.4vw);
  }

  p,
  ol,
  h2 {
    margin-bottom: 0.2rem;
  }

  h2 {
    font-size: max(1.6rem, 1.8vh);
    line-height: 2rem;
    font-weight: 600;
  }

  ol {
    list-style: none;
    margin-left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 95%;
    padding-bottom: 2rem;
    max-height: 27rem;

    li {
      counter-increment: custom;

      &::marker {
        font-weight: 600;
        content: counter(custom) " ";
      }

      &:first-child {
        counter-reset: custom;
      }
    }
  }
}

.extension-card {
  background-color: #f0f8fc;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .flippableCard {
    height: 100%;
    width: 95%;
    margin: auto;
  }

  .question-card {
    padding: 0;
  }

  button {
    //background-color: $cyan-8;
    color: $cyan-8;
    font-size: 1.6rem;
    font-weight: 600;

    &.mainButton {
      background: none;
      color: #0058de;
      font-size: 1.6rem;
      font-weight: 700;
      border: 1px solid #0058de;
      width: 100%;
    }
  }
}

.extension {
  &.modal {
    padding: 0;
    background: none !important;

    .questionHeaderRight {
      margin-right: 3rem;
    }

    .flippableCard {
      height: 95%;
    }

    .q-refresh-fn {
      padding-right: 1em;
    }

    .ant-modal-close-x {
      margin-top: -4px;
      width: 40px;
    }
  }

  &,
  .ant-modal-content,
  .ant-modal-body,
  .ant-modal-footer {
    background-color: #f0f8fc;
    border-radius: 8px;
  }

  .ant-modal-content {
    border: 1px solid #0058de;

    .ant-modal-body {
      padding: 0;
    }
  }

  .ant-modal-footer {
    border-radius: 8px;

    .extensionFooterButtons {
      text-align: end;
      margin-bottom: 0;
    }
  }
}

.questionImage {
  max-width: 100%;
  max-height: 70%;
  padding-bottom: 1rem;
}

.spinContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.question {
  margin-top: 1rem;
  flex-grow: 1;
  text-align: center;
  height: 0;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;

  &.clickable {
    cursor: pointer;
  }

  .questionWrapper {
    overflow: hidden;
    flex-shrink: 0;
    height: 100%; // needs to be height not max-height for image resizing
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: left;

    &.row {
      justify-content: space-around;
      flex-direction: row;

      .questionImage {
        max-height: 100%;
        padding-right: 1rem;
        padding-bottom: 0;
      }
    }
  }

  div {
    transition: font-size 0.05s linear;
  }
}

.answer {
  &.hide {
    visibility: hidden;
  }
}

.question-number {
  top: 0;
  left: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0 0 5px 0;
  border-bottom: 1px solid #ced5e1d1;

  h1 {
    margin: 0.3rem 1rem 0 0.5rem;
  }
}

.q-refresh-fn {
  display: flex;
  justify-content: flex-end;

  button {
    background: none;
    border: 0;
    font-size: 1.6rem;
    margin-left: 1.15rem;
    margin-top: 4px;
    color: #a8a8a8;

    &:hover {
      color: $primary;
    }
  }
}

.questionHeaderRight {
  flex-grow: 1;
  text-align: right;
  align-items: flex-end;
  align-content: flex-end;
}

.calc-icon {
  display: flex;
  align-items: center;
  margin: 0.3rem 0 0;
  font-size: 2.7rem;

  img {
    height: 1em;
    width: 1em;
  }

  span:not(.anticon) {
    font-family: "Montserrat", "Nunito Sans", sans-serif;
    font-weight: 800;
    font-size: 1.8rem;
    color: #272727;
  }
}

.whiteboard-btn {
  &,
  .anticon {
    color: $primary;
  }
}

.recall-tag-container {
  font-size: 2.2rem;
}

.recall-tag-container {
  .ant-tag-close-icon {
    margin-left: 5px;
    margin-top: 2px;
  }
}

.recallTagDisplay {
  font-size: 1.6rem;
  font-weight: 700;
  padding: 4px 8px 2px;
  border-radius: 4px;
}

.recall-tag-container {
  .ant-tag-close-icon {
    line-height: 2rem;
  }
}

.question-fn {
  display: flex;
  justify-content: flex-end;

  button {
    background: none;
    border: 0;
    font-size: 2rem;
  }
}

.q-back-fn {
  flex-grow: 1;
  text-align: left;

  button {
    color: #a8a8a8;

    &:hover {
      color: $primary;
    }
  }
}

.medium-f {
  font-size: 5rem;
}

.fullscreenChartModal {
  .ant-modal-body {
    height: 95vh;
    display: grid;
    place-content: center;
  }

  &__chart {
    max-height: 100%;
    max-width: 100%;
  }
}

.sketchModal {
  &.tourOpen {
    height: 80%;

    .ant-modal-body {
      max-height: 70vh;
    }
  }

  &-footer {
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(3, 8, 24, 0.1);
    width: fit-content;
    padding: 1rem 1.2rem;
    margin: 0.5rem auto 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 90%;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__nav_btns {
      display: flex;
      gap: 1rem;
      margin-right: 1rem;
    }

    .ant-btn {
      padding: 0 4px;

      &:not(&-primary):not(:disabled) {
        color: $primary;
      }
    }
  }

  .ant-drawer-body {
    height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
    cursor: crosshair;

    .canvasContainer,
    .canvasContainer > div,
    .konvajs-content {
      height: 150%;
      width: 100%;
    }

    .konvajs-content {
      width: 100% !important;
      height: 100% !important;
      overflow: hidden;
    }
  }

  .ant-drawer-footer {
    border-top: none;
  }

  .colourOptions {
    width: fit-content;
    margin: 0 0.4rem;
    font-size: 2rem;

    .colourSelectorContainer {
      gap: 0.8rem;
    }

    .red {
      color: red;
      background: $red-2;
    }

    .blue {
      color: blue;
      background: $blue-2;
    }

    .black {
      color: black;
      background: $gray-5;
    }

    .green {
      color: green;
      background: $green-2;
    }

    .white {
      background: white;
    }
  }

  .canvasContainer {
    margin-top: -1rem;
    touch-action: none;
  }

  .questionBackground {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    height: 0;
    position: fixed;
    width: 92%;
    margin-left: 2rem;
    padding-top: 1rem;
    font-size: 2.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .questionText,
    .questionWrapper {
      display: flex;
      flex-direction: column;
      width: fit-content;
      background: white;
    }

    .questionWrapper {
      margin-right: 1rem; // For close button
      max-height: 75vh;
    }

    .questionText {
      margin-bottom: 2rem;
      z-index: 4;
      background: white;
      border: 1px dashed $gray-6;
      border-radius: 8px;
      padding: 8px;
    }

    .questionImage {
      min-height: 20rem;
      min-width: 30rem;
      margin-right: auto;
      object-fit: contain;
    }

    .answerContainer {
      width: fit-content;
      text-align: left;
      z-index: 4;
      height: fit-content;
      border-radius: 6px;
      background: white;
      padding: 0;

      &.answerVisible {
        padding: 1rem;
        background: $primary;
        min-width: 30rem;
      }

      .answer {
        color: white;

        & > div {
          padding-top: 0;
          padding-bottom: 1rem;
          border-top: none;
        }
      }
    }
  }

  .questionClose {
    background: white;
    z-index: 4;
    position: fixed;
    right: 2rem;
    top: 2rem;
    width: fit-content;
  }

  .scrollButtons {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    position: absolute;
    right: 3rem;
    bottom: 6em;
    @include media("<957px", ">=520px") {
      bottom: 8em;
    }
    @include media("<520px", ">=370px") {
      bottom: 10em;
    }
    @include media("<370px") {
      bottom: 12em;
    }

    button {
      border-radius: 2rem;
      width: 4rem;
      padding: 0;
      height: 4rem;
    }
  }

  .sketchCanvas {
    overflow-y: scroll;
    height: 100% !important;
  }
}

.sketch-answer-modal {
  border-style: solid;
  border-width: 2px;
  border-radius: 1rem;

  .ant-modal-header,
  .ant-modal-content {
    border-radius: inherit;
    background: inherit;
  }
  .ant-modal-header {
    padding-bottom: 1rem;
  }
  .ant-modal-body {
    padding: 2rem 4rem 5rem;
    text-align: center;
  }

  .hasChart {
    min-height: 60vh;
    width: 80vh;
    max-width: 95vw;
    max-height: 95vh;

    canvas {
      max-height: calc(100vh - 20rem);
      max-width: calc(100vw - 20rem);
    }
  }
}
