@import "colors";

.starter-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: white;
  font-family: $font;
}

.grid-container {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.main-starter-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .extension-button-container {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    width: 100%;
    padding: 0 5px 1.5rem 30px;

    #btn-extension {
      margin-left: -5px;
      background: white;
      border-color: $blue;
      color: $blue;
      height: 4rem;
      width: 100%;
      font-size: 1.8rem;
      font-weight: 700;

      svg {
        margin-bottom: -3px;
        margin-right: 5px;
        font-size: 1.9rem;
      }
    }

    #btn-extension:hover {
      background: $blue;
      color: white;
    }
  }
}

.starter-right-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem 0 0.5rem;
  max-width: 5rem;
  background: white;

  &.top {
    justify-content: space-between;
    padding-top: 2.2rem;
  }
}

/* SLIDER ------------------------------------------------------------------- */

.slider {
  text-align: center;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;

  .starter-slide {
    scroll-snap-align: end;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.75s;
    position: relative;
    flex-wrap: nowrap;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.slider-toggle-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 3px;
  z-index: 999;

  .sliderToggle {
    color: white;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0.1rem;

    b {
      width: 100%;
    }
  }
}

.plenary-btn {
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: black;
    border-color: black;
  }
}

.starter-btn {
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: black;
    border-color: black;
  }
}

/* HEADER -------------------------------------------------------------------- */

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 1.5rem;
  align-items: center;
  position: relative;
}

.switcher-date {
  align-self: flex-end;
  margin-bottom: 0.75rem;
  margin-top: 3.5rem;
}

.switcher-date--starter {
  position: absolute;
  z-index: 1;
}

.date {
  font-size: 2.9rem;
  font-weight: 700;
  text-align: center;
  padding-top: 1rem;
  margin-left: 1rem;
  margin-top: 2rem;
}

.header.fullscreen {
  min-height: 10rem;

  .switcher-date {
    margin-top: 0;
    height: 100%;
    padding-top: 2rem;
  }

  .date {
    padding-top: 0;
    margin-top: 0;
    margin-bottom: auto;
  }
}

.starter-header-buttons {
  .custom_button {
    & > .small-label {
      display: none;
    }

    & > *:not([role="img"]) {
      margin-left: 8px;
    }

    @media (max-width: 1800px) {
      & > *:not([role="img"]):not(.small-label) {
        display: none;
      }
    }

    @media (max-width: 1800px) and (min-width: 1200px) {
      & > .small-label {
        display: inline-block;
      }
    }
  }
}

.plenary-header-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  .ant-btn {
    padding: 0.75rem 3rem;
    height: 100%;
    border-radius: 0.5rem;
  }

  .ant-btn-default {
    border-color: $primary;
    color: $primary;

    &:hover {
      background: black;
      color: white;
    }
  }
}

.starter-plenary-switch {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  z-index: 999;
  margin-left: 1.5rem;
  padding: 0 4px 4px;
  border-radius: 0;
  background: white;

  button {
    flex-grow: 1;
    min-width: fit-content;
    padding: 3px 10px;
    font-size: 1.3rem;
    font-weight: 700;
    background: #ffffff;
    color: black;
    transition: ease-in-out cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      background: black;
      color: white;
    }
  }

  button:nth-child(1) {
    border-left: 1px solid $primary;
    border-right: 1px solid $primary;
    border-bottom: 1px solid $primary;
  }
  button:nth-child(2) {
    border-right: 1px solid $primary;
    border-bottom: 1px solid $primary;
  }

  button.win--active {
    background: $primary;
    color: white;
    font-weight: 700;
  }
}

.title {
  flex-grow: 1;
  padding: 0;
  text-align: center;

  .hidden {
    max-height: 0;
  }

  input,
  .ant-typography {
    width: 100%;
    text-align: center;
    font-size: 4rem;
    background: none;
    border: 0;
    font-weight: 700;
    padding-left: 0.5em;
  }
}

input.learning-obj {
  font-size: 2.6rem;
  font-weight: 600;
  margin-top: -5px;
}

/* TIMER --------------------------------------------------------------------*/

.cntdwn-timer {
  margin: 0;
  font-size: 5rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #d3ad3a;
  background: #fff2bd;
  width: fit-content;
  flex-grow: 0;
  height: 100%;

  .timerText {
    font-size: inherit;
    margin-right: 0.2em;
    color: black;
    line-height: 1;
    margin-top: 0.75rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}

.timer-main-controls {
  display: flex;
  background: inherit;
  align-items: center;
  justify-content: space-around;
  border-radius: 0.7rem 0 0 0.7rem;
  column-gap: 0.23em;
  padding: 0 0 0 0.23em;

  svg {
    width: 1em;
    height: auto;
    margin-right: 0.23em;
  }
}

.timer-sec-controls {
  display: flex;
  font-size: 0.5em;
  //color: $green-10;
  flex-direction: row;
  background: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  padding: 0.15em 0.4em;
  justify-content: space-around;
  width: 100%;
}

.timer-main-controls,
.timer-sec-controls {
  svg {
    cursor: pointer;

    &.secondary {
      color: #d3ad3a;
    }

    &:hover {
      color: $primary;
    }
  }
}

.functions {
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  z-index: 1;
  right: 0;
}

.function-btns {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 6px;

  button {
    border-radius: 3px;
    border: none;
    box-shadow: none;
    margin-bottom: 18px;
    color: $primary;

    &:hover {
      color: black !important;
    }

    svg {
      width: 2.2rem;
      height: 2.2rem;
      color: inherit;
    }
  }

  button:nth-child(1) {
    margin-bottom: 15px;
  }
}

.starter-btn,
.plenary-btn {
  padding: 0 11px;
}

.plenary-btn,
.starter-btn {
  &.ant-tooltip-disabled-compatible-wrapper,
  &.ant-tooltip-disabled-compatible-wrapper:hover {
    background-color: transparent;
    border-color: transparent;
    padding: 0;

    a {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.topicsPopup {
  li.ant-cascader-menu-item {
    display: flex;
    justify-content: flex-end;

    .difficultySpan {
      width: 100%;
    }
  }

  .topicNewTag {
    color: #214d3c;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 800;
    margin-left: 1rem;
    line-height: 1.2;
    border-radius: 0.33rem;
    padding-inline: 0.66rem;
  }

  .topicNewDot {
    margin-left: 0.75rem;
    margin-right: 0.25rem;
  }
}

.topicSelector {
  align-self: flex-start;
  width: 100%;

  .topicNewTag,
  .topicNewDot {
    display: none;
  }
}

.starterModalButtons {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.starterModalActionButtons {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .actionButton {
    width: fit-content;
    margin-right: 1rem;
  }
}

.videoContainer {
  overflow: hidden;
  width: 100%;
  height: 60vh;

  iframe {
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
}

.help-image-modal {
  .ant-modal-body {
    max-height: 90vh;
    overflow-y: auto;
  }

  .help-image {
    width: 100%;
    padding: 1rem;
  }
}

.mock-papers-advert-button {
  position: absolute;
  bottom: 8.1%;
  left: 9%;
  height: 11.5%;
  width: 32%;
  background: #5d9879;

  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &:is(a).ant-btn {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    line-height: 1.5 !important;
    display: grid;
    place-content: center;
  }

  & * {
    font-family: "recoleta", serif;
    color: white;
    font-size: 120%;
  }

  &:hover {
    background: #375947;
  }
}

.homeButton {
  z-index: 999;
  position: absolute;
  left: 1.5rem;
  transition: top 200ms ease 300ms;

  &.starter,
  &.plenary.fullscreen {
    top: 5.5rem;
  }

  &.plenary {
    top: 3.5rem;
  }
}

.changeTypeButton {
  margin-left: 6rem;
}
