.lesson_type_modal {
  .ant-modal-content {
    border-radius: 1rem;
  }

  .ant-modal-body {
    padding: 3rem;
    display: flex;
    max-height: 94vh;
    min-height: min(100vh, 56rem);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 0;
    width: 100%;

    > *:not(.lesson_type_modal__options),
    .lesson_type_modal__options > * {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    ul,
    ol {
      margin-left: 2rem;
      margin-bottom: 0;
    }

    > button {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  &__heading {
    margin: 0;
    text-transform: uppercase;
    color: $primary;
    font-weight: 600;
  }

  &__options {
    overflow-y: auto;
  }

  &__accent_box {
    border-radius: 1rem;
    padding: 2rem;
    background: #f9f9f9;
    border: 1px solid #eeeeee;

    p {
      color: $gray-8;
      font-size: 1.4rem;
      margin-bottom: 0;

      .ant-btn-link {
        font-size: inherit !important;
      }
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  .ant-btn-dangerous:hover {
    color: #fff;
    border-color: #ff4d4f;
    background: #ff4d4f;
  }
}

.starter_type_selector {
  &__option {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;

    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    border-radius: 1rem;
    cursor: pointer;

    &:hover {
      &.blue {
        background: #f3f7fe;
        h2 {
          color: #2b66f2;
        }
      }
      &.purple {
        background: #f6f0fd;
        h2 {
          color: #7734d6;
        }
      }
      &.pink {
        background: #fdf3f9;
        h2 {
          color: #ec5bb2;
        }
      }
      &.green {
        background: #eafaf9;
        h2 {
          color: #2fccc4;
        }
      }
      &.yellow {
        background: #fef9e6;
        h2 {
          color: #daab02;
        }
      }
    }

    &__text {
      h2,
      p {
        margin: 0;
      }

      h2 {
        font-weight: 800;
        font-size: 1.7rem;
      }

      p {
        font-size: 1.4rem;
      }
    }
  }

  &__drop_down {
    &__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      cursor: pointer;
    }

    &__content {
      overflow: hidden;
      transition: max-height 400ms ease-out;
    }

    &.hidden &__content {
      max-height: 0;
    }

    &.visible &__content {
      max-height: 11rem;
    }
  }

  &__details_form {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    & > .ant-form-item {
      flex: 1 1 auto;
      min-width: fit-content;
      margin-bottom: 0;

      &:last-of-type {
        flex-grow: 0;
      }
    }

    .ant-tag {
      cursor: pointer;
      min-width: 9rem;

      &:not(.addClassTag) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 1rem;
      }

      svg {
        font-size: 1.1em;
        color: inherit;
        margin-bottom: 2px;
      }
    }

    .ant-form-item-label label {
      font-size: 1.4rem;
    }

    .ant-input {
      color: $gray-7;

      &-suffix {
        color: $gray-6;
        cursor: pointer;
      }
    }
  }

  &__save_location {
    margin-top: 1.5rem;

    &--accented {
      margin-left: 1rem;
      color: $primary;
      font-weight: 600;
    }
  }
}

.gcse_grade_options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ant-checkbox-group-item {
    margin-right: 4px;

    .ant-checkbox + span {
      padding-left: 6px;
    }

    &:last-of-type {
      margin-right: 0;

      .ant-checkbox + span {
        padding-right: 0;
      }
    }
  }
}

.gcse-generate {
  border-radius: 0.5rem;
}

.gcse-num-q-label {
  color: #000000d9 !important;

  &.disabled {
    color: #00000044 !important;
  }
}

.gcse_or {
  width: 100%;
  margin-bottom: 0;
  text-align: center;
}

.gcse-num-q-toggle-container {
  display: flex;
  flex-direction: row;
  height: 2.75rem;
  width: 6.5rem;

  > * {
    flex: 1 1 100%;
    max-height: 100%;
    line-height: 1;
  }

  > *:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > *:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.mock_paper_starter_modal {
  .mock-papers-selector__side-panel {
    min-width: 16rem;
    width: 25%;
    max-width: 35rem;

    .subtitle {
      font-family: "Montserrat", "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      margin-top: 2rem;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.mock_paper_starter__questions {
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;

  .question_num_and_subtopic_list {
    min-height: 32rem; // Ensures modal overlaps parent fully if there are a small number of questions
  }
}
